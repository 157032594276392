<script>
import BaseVue from "@frontend/Base.vue";
import {
  UserIcon,
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
} from "vue-feather-icons";
import Gen from "../helper/Gen";
export default {
  extends: BaseVue,
  data() {
    return {
      heroImage: {},
      data: {},
      popularNews: [],
      category: [
        { type: 1, name: "Artikel", slug: "artikel" },
        { type: 2, name: "Berita", slug: "berita" },
      ],
      selectedCategory: this.$route.params.tipe || "",
      selectedSubCategory: this.$route.params.kategori || "",
      subCategory: [],
    };
  },
  computed: {},
  components: {
    UserIcon,
    CalendarIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ArrowLeftIcon,
  },
  async mounted() {
    const { tipe, kategori, tags } = this.$route.params;
    if (tipe) {
      this.getNewsSubCategory(tipe, kategori || null);
    }
    else if(tags){
      this.getNewsTags(tags)
    } 
    else {
      this.getNewsArticle();
    }
    this.getPopularNewsArticle();

    this.$set(this.$root, "page", this);
  },
  methods: {
    getNewsArticle() {
      Gen.apirest(
        "/news-article",
        { page: this.$route.query.page },
        (err, resp) => {
          if (err) console.log("About goodness error " + err);
          if (resp.data.news.data.length == 0) return this.$router.push({name: this.$route.name})
          this.data = resp.data.news;
          for (let i = 0; i < this.data.data.length; i++) {
            this.data.data[i].an_publish_date = this.dateStrFormat(this.data.data[i].an_publish_date);
          }
          this.refreshMeta(this.$route.path);
          this.heroImage = resp.heroImage;
        }
      );
    },

    changePage(page = 1) {
      
      this.$router.push({
        query: Object.assign(Gen.clone(this.$route.query), { page: page }),
      });
      
      $([document.documentElement, document.body]).animate(
          {
            scrollTop: $(".article").offset().top - 150,
          },
          500
      );
    },

    getPopularNewsArticle() {
      Gen.apirest("/news-article/popular", {}, (err, resp) => {
        if (err) console.log("Popular News Article error" + err);
        this.popularNews = resp.data;
        this.heroImage = resp.heroImage;
        for (let i = 0; i < this.popularNews.length; i++) {
          this.popularNews[i].an_publish_date = this.dateStrFormat(this.popularNews[i].an_publish_date);
        }
      });
    },

    getNewsSubCategory(tipe, kategori = null) {
      if (!tipe || tipe == "") {
        this.$router.push({ name : 'NewsArticle' });        
        this.getNewsArticle();
        return;
      }

      this.refreshMeta(this.$route.path);

      const url = kategori ? `${tipe}/${kategori}` : tipe;
      Gen.apirest(
        "/news-article/category/" + url,
        { page: this.$route.query.page },
        (err, resp) => {
          if (err) console.log(err);
          if (resp.data.news.data.length == 0 && this.$route.params.tipe && this.$route.params.kategori){
            return this.$router.push({name: 'NewsArticle', query:{tipe : null}})
          } 
          this.subCategory = resp.data.subCategory;
          this.data = resp.data.news;
          for (let i = 0; i < this.data.data.length; i++) {
            this.data.data[i].an_publish_date = this.dateStrFormat(this.data.data[i].an_publish_date);
          }
          this.heroImage = resp.heroImage;
        }
      );
    },
    getNewsTags(tags){
      this.refreshMeta(this.$route.path);
      Gen.apirest(
        "/news-article/tags/"+ tags,
        { page: this.$route.query.page },
        (err, resp) => {
          if (err) console.log("About goodness error " + err);
          if (resp.data.news.data.length == 0) return this.$router.push({name: this.$route.name})
          this.data = resp.data.news;
          this.tags = resp.label
          for (let i = 0; i < this.data.data.length; i++) {
            this.data.data[i].an_publish_date = this.dateStrFormat(this.data.data[i].an_publish_date);
          }
          this.heroImage = resp.heroImage;
        }
      );
    },
    setQueryCategory() {

      const params = this.selectedSubCategory
        ? { tipe: this.selectedCategory, kategori: this.selectedSubCategory }
        : { tipe: this.selectedCategory };
      if(params.tipe === ""){
        this.$router.push({
          name: 'NewsArticle',
        });
      }
      else{
        this.$router.push({
          name: 'NewsArticleCategory',
          params : params,
        });
      }
    },
    refreshMeta(url) {
      Gen.rest(url, { metainfo: true }, (err, resp) => {
        if (err) return console.error(err);
        setTimeout(()=>{
          $("[rel=alternate]").remove()
          $("[rel=canonical]").remove()
          
          if(this.$route.name == "Index"){
            $("head").append('<link rel="canonical" href="https://www.sdi-alhidayah.sch.id">')
            $("[name='robots']").attr('content','index,follow')
            $("[name='googlebot']").attr('content','index,follow')
          } 
          else if(this.$route.name && this.$route.name !== "404"){
            $("head").append(`<link rel="alternate" href="${window.location.href}" hreflang="id-id" />`)
            $("head").append(`<link rel="alternate" href="${window.location.href}" hreflang="x-default" />`)  
            $("[name='robots']").attr('content','index,follow')
            $("[name='googlebot']").attr('content','index,follow')
          }
          else{
            $("[name='robots']").attr('content','noindex,nofollow')
            $("[name='googlebot']").attr('content','noindex,nofollow')
          }
        },2000)

        $("title").text(resp.meta_title);
        $("meta[name='keywords']").attr('content', resp.meta_keyword)
        $("meta[name='description']").attr('content', resp.meta_desc)
        $("meta[name='author']").attr("content", 'SDI Al Hidayah');

        // OG
        $("meta[property='og:image']").attr('content', resp.ss_img)
        $("meta[property='og:title']").attr('content', resp.meta_title)
        $("meta[property='og:keywords']").attr('content', resp.meta_keyword)
        $("meta[property='og:description']").attr('content', resp.meta_desc)
        $("meta[property='og:url']").attr('content', resp.ss_url)
        
        // Twitter
        $("meta[name='twitter:image']").attr('content', resp.ss_img)
        $("meta[name='twitter:title']").attr('content', resp.meta_title)
        $("meta[name='twitter:description']").attr('content', resp.meta_desc)
        $("meta[name='twitter:url']").attr('content', resp.ss_url)
        this.$root.meta = resp;
      });
    },
  },
  watch: {
    $route() {
      const { tipe, kategori, tags } = this.$route.params;
      
      if (!tipe || tipe == "") {
        this.$router.push({
          query: Object.assign({}, { page: this.$route.query.page }),
        });  
      }

      if (tipe && tipe !== "") {
        $("html, body").animate(
          {
            scrollTop: $(".article").offset().top - 250,
          },
          500
        );
        this.getNewsSubCategory(tipe, kategori);
      }
      else if(tags){
        this.getNewsTags(tags)
      } 
      else {
        this.getNewsArticle();
      }
      
      let width = $(window).width()
      if(this.$route.query || this.$route.params && width>991){
        setTimeout(()=>{
          $("#header").addClass("sticky-header")
          $("header-wrap").addClass("not-dark") 
        },500)
      }

      this.selectedCategory = tipe || "";
      this.selectedSubCategory = kategori || "";
    },
    selectedCategory() {
      this.selectedSubCategory = "";
      this.setQueryCategory();
    },
  },
};
</script>
<template>
  <div>
    <section
      v-if="!this.$root.isMobile"
      id="page-title"
      :style="`--title-img:url('${uploader(heroImage.img)}')`"
    >
      <div class="container">
        <span class="d-none position-absolute">{{heroImage.alt_img}}</span>
        <div class="vertical-middle">
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <h2 itemprop="alternativeHeadline" class="page__title">Berita dan Artikel</h2>
              <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb">
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >
                  <router-link :to="{ name: 'Index' }">Home</router-link>
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item active"
                  aria-current="page"
                >
                  Berita dan Artikel
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-else
      id="page-title"
      :style="`--title-img:url('${uploader(heroImage.img_mobile)}')`"
    >
      <div class="container">
        <span class="d-none position-absolute">{{heroImage.alt_img_mobile}}</span>
        <div class="vertical-middle">
          <div class="row">
            <div class="col-lg-5 col-md-6">
              <h2 itemprop="alternativeHeadline" class="page__title">Berita dan Artikel</h2>
              <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb">
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item"
                >
                  <router-link :to="{ name: 'Index' }">Home</router-link>
                </li>
                <li 
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                  class="breadcrumb-item active"
                  aria-current="page"
                >
                  Berita dan Artikel
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="content">
      <div class="content-wrap">
        <section class="section py-64">
          <div itemscope itemtype="https://schema.org/Article" v-if="(data.data||[]).length > 0" class="container">
            <div class="main-list">
              <div class="row">
                <div class="col-lg-8">
                  <div class="main-list-filter my-4">
                    <div class="text-center" v-if="$route.params.tags">
                      <h3 itemprop="alternativeHeadline" class="mb-1">Tag Terkait "{{tags}}"</h3>
                      <span class="d-flex align-items-center">
                       <arrow-left-icon size="1.2x" class="text-success"></arrow-left-icon>
                       <router-link :to="{ name: 'NewsArticle' }">Kembali</router-link> 
                      </span>
                    </div>
                    <div v-else>
                      <form action="" class="form-inline">
                        <select
                          name="mainCat"
                          id="mainCat"
                          class="form-control custom-select mr-md-3 mb-2 mb-md-0"
                          v-model="selectedCategory"
                        >
                          <option value="">Semua Tipe</option>
                          <option
                            v-for="(item, index) in category"
                            :key="index"
                            :value="item.slug"
                            >{{ item.name }}</option
                          >
                        </select>
                        <select
                          name="subCat"
                          id="subCat"
                          class="form-control custom-select"
                          @change="setQueryCategory"
                          v-show="selectedCategory"
                          v-model="selectedSubCategory"
                        >
                          <option value="">Semua Kategori</option>
                          <option
                            v-for="(item, index) in subCategory"
                            :key="index"
                            :value="item.anc_slug"
                            >{{ item.anc_category }}</option
                          >
                        </select>
                      </form>
                    </div>
                  </div>
                  <div class="row col-mb-48">
                    <div class="col-md-6" v-for="(v, k) in data.data" :key="k">
                      <article class="article">
                        <figure class="article-media">
                          <router-link
                            :to="{
                              name: 'NewsArticleDetail',
                              params: { slug: v.an_slug },
                            }"
                            ><img
                              :src="uploader(v.an_img)"
                              :alt="v.an_alt_img"
                              :title="v.an_alt_img"
                              class="article__img"
                              itemprop="thumbnailUrl"
                          /></router-link>
                          <div class="article-media-badges d-flex justify-content-end align-items-center flex-wrap">
                            <router-link
                              :to="{
                                name: 'NewsArticleCategory',
                                params : {tipe : 'artikel'},
                              }"
                              v-if="v.anc_type == '1'"
                              class="article-badge mb-1 color-2"
                              itemprop="articleSection"
                              >Artikel</router-link
                            >
                            <router-link
                              :to="{
                                name: 'NewsArticleCategory',
                                params : {tipe : 'berita'},
                              }"
                              v-else-if="v.anc_type == '2'"
                              class="article-badge mb-1"
                              itemprop="articleSection"
                              >Berita</router-link
                            >
                            <router-link
                              :to="{
                                name: 'NewsArticleCategory',
                                params: { tipe: v.anc_type == '1' ? 'artikel' : 'berita', kategori: v.anc_slug },
                              }"
                              :class="[
                                'article-badge mb-1',
                                v.anc_type == '1' ? 'color-2' : '',
                              ]"
                              itemprop="articleSection"
                              >{{ v.anc_category }}</router-link
                            >
                          </div>
                        </figure>
                        <figcaption class="article-meta">
                          <h3
                            class="article__title"
                            v-tooltip="
                              v.an_title.limitChar(50).endsWith('...')
                                ? v.an_title
                                : ''
                            "
                            itemprop="name"
                          >
                            <router-link
                              :to="{
                                name: 'NewsArticleDetail',
                                params: { slug: v.an_slug },
                              }"
                              >{{ v.an_title.limitChar(50) }}</router-link
                            >
                          </h3>
                          <p itemprop="description" class="article__shortdesc">
                            <router-link
                              :to="{
                                name: 'NewsArticleDetail',
                                params: { slug: v.an_slug },
                              }"
                              >{{ v.an_desc.limitWord(13) }}</router-link
                            >
                          </p>
                          <div class="article-meta-auth">
                            <span itemprop="author" class="article-meta-auth__info d-flex">
                              <div>
                                <user-icon></user-icon> 
                              </div>
                              <div>
                                {{ v.an_author }}
                              </div>
                            </span>
                            <span itemprop="datePublished" class="article-meta-auth__info d-flex">
                              <div>
                                <calendar-icon></calendar-icon>
                              </div>
                              <div>
                                {{ v.an_publish_date }}
                              </div>
                            </span>
                          </div>  
                        </figcaption>
                      </article>
                    </div>
                  </div>
                  <Pagination
                    :limit="2"
                    :data="data"
                    @pagination-change-page="changePage"
                    class="pagination pagination-transparent justify-content-center d-flex d-md-none mb-0"
                  >
                    <chevron-left-icon slot="prev-nav"></chevron-left-icon>
                    <chevron-right-icon slot="next-nav"></chevron-right-icon>
                  </Pagination>
                </div>
                <aside class="col-lg-4">
                  <div class="side-list mt-5 mt-lg-0">
                    <h3 itemprop="alternativeHeadline" class="side-list__title">Berita dan Artikel Populer</h3>
                    <div
                      class="side-article"
                      v-for="(v2, k2) in popularNews"
                      :key="k2"
                    >
                      <div class="row gutter-16 col-mb-32">
                        <div class="col-5">
                          <router-link
                            :to="{
                              name: 'NewsArticleDetail',
                              params: { slug: v2.an_slug },
                            }"
                            class="side-article-media"
                          >
                            <img
                              :src="uploader(v2.an_img)"
                              :alt="v2.an_alt_img"
                              :title="v2.an_alt_img"
                              class="side-article__img"
                              itemprop="thumbnailUrl"
                            />
                          </router-link>
                        </div>
                        <div class="col-7">
                          <div class="side-article-meta">
                            <div class="side-article-badges">
                              <router-link
                                :to="{
                                  name: 'NewsArticleCategory',
                                  params: { tipe: 'artikel' },
                                }"
                                v-if="v2.anc_type == '1'"
                                class="side-article__badge article-badge color-2"
                                itemprop="articleSection"
                                >Artikel</router-link
                              >
                              <router-link
                                :to="{
                                  name: 'NewsArticleCategory',
                                  params: { tipe: 'berita' },
                                }"
                                v-else-if="v2.anc_type == '2'"
                                class="side-article__badge article-badge"
                                itemprop="articleSection"
                                >Berita</router-link
                              >
                              
                              <router-link
                                :to="{
                                  name: 'NewsArticleCategory',
                                  params: { tipe: v2.anc_type == '1' ? 'artikel' : 'berita', kategori: v2.anc_slug },
                                }"
                                :class="[
                                  'side-article__badge',
                                  'article-badge',
                                  v2.anc_type == '1' ? 'color-2' : '',
                                ]"
                                itemprop="articleSection"
                                >{{ v2.anc_category }}</router-link
                              >
                            </div>
                            <div class="side-article-content">
                              <h4 itemprop="name" class="side-article__title">
                                <router-link
                                  :to="{
                                    name: 'NewsArticleDetail',
                                    params: { slug: v2.an_slug },
                                  }"
                                  v-tooltip="
                                    v2.an_title.limitChar(46).endsWith('...')
                                      ? v2.an_title
                                      : ''
                                  "
                                  >{{ v2.an_title.limitChar(46) }}</router-link
                                >
                              </h4>
                              <span itemprop="author" class="side-article__auth"
                                ><user-icon></user-icon>
                                {{ v2.an_author }}</span
                              >
                              <span itemprop="datePublished" class="side-article__auth"
                                ><calendar-icon></calendar-icon>
                                {{ v2.an_publish_date }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
              <Pagination
                :data="data"
                :limit="2"
                @pagination-change-page="changePage"
                class="pagination pagination-transparent justify-content-center d-none d-md-flex"
                itemprop="pagination"
              >
                <chevron-left-icon slot="prev-nav"></chevron-left-icon>
                <chevron-right-icon slot="next-nav"></chevron-right-icon>
              </Pagination>
            </div>
          </div>
          <div class="container text-center" v-else>
            <h3 itemprop="alternativeHeadline" class="fw-bold">Berita & Artikel belum tersedia</h3>
          </div>
        </section>
      </div>
    </section>
    <!-- #content end -->
  </div>
</template>
